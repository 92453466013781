import React from "react";
import PropTypes from "prop-types";

function Body({ children, isSidebarMenuVisible }) {
  return (
    <div
      className={
        "font-quicksand bg-light text-dark " +
        (isSidebarMenuVisible ? "overflow-hidden" : "")
      }
      id="body"
    >
      {children}
    </div>
  );
}

Body.propTypes = {
  children: PropTypes.node.isRequired
};

export default Body;
